import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/firma'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'

const Firma = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/kontakt.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      imagePoznajNas: file(
        absolutePath: { regex: "/images/intros/poznaj-nas.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageWspolpraca: file(
        absolutePath: { regex: "/images/intros/wspolpraca.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageCSR: file(absolutePath: { regex: "/images/intros/csr.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageKariera: file(
        absolutePath: { regex: "/images/intros/kariera.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageOfertyPracy: file(
        absolutePath: { regex: "/images/intros/oferty-pracy.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imagePolityka: file(
        absolutePath: { regex: "/images/intros/polityka-firmy.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageNews: file(absolutePath: { regex: "/images/intros/blog.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageWiedza: file(absolutePath: { regex: "/images/intros/wiedza.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imagePrzewoznik: file(
        absolutePath: { regex: "/images/intros/przewoznik.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageHistoria: file(
        absolutePath: { regex: "/images/intros/historia.jpeg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageDlaMediow: file(
        absolutePath: { regex: "/images/intros/dla-mediow.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imagePrezentacja: file(
        absolutePath: { regex: "/images/intros/presentation.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
    }
  `)

  const links = [
    {
      slug: 'poznaj-nas',
      name: lang === 'en' ? 'Discover Us' : 'Poznaj Nas',
      image: query.imagePoznajNas,
    },
    {
      slug: 'historia',
      name: lang === 'en' ? 'Historia' : 'Historia',
      image: query.imageHistoria,
    },
    {
      slug: 'wspolpraca',
      name: lang === 'en' ? 'Collaboration' : 'Współpraca',
      image: query.imageWspolpraca,
    },
    {
      slug: 'csr',
      name: lang === 'en' ? 'CSR' : 'CSR',
      image: query.imageCSR,
    },
    {
      slug: 'kariera',
      name: lang === 'en' ? 'Career' : 'Kariera',
      image: query.imageKariera,
    },
    {
      slug: 'oferty-pracy',
      name: lang === 'en' ? 'Job Offers' : 'Oferty Pracy',
      image: query.imageOfertyPracy,
    },
    {
      url: '/blog/?category=wydarzenia',
      name: lang === 'en' ? 'News' : 'Wydarzenia',
      image: query.imageNews,
    },
    {
      url: '/blog/?category=wiedza',
      name: lang === 'en' ? 'Knowledge' : 'Wiedza',
      image: query.imageWiedza,
    },
    {
      slug: 'przewoznik',
      name: lang === 'en' ? 'Carrier Zone' : 'Strefa Przewoźnika',
      image: query.imagePrzewoznik,
    },
    {
      slug: 'polityka-firmy',
      name: lang === 'en' ? 'Company Policies' : 'Polityka Firmy',
      image: query.imagePolityka,
    },
    {
      slug: 'dla-mediow',
      name: lang === 'en' ? 'Dla Mediów' : 'Dla Mediów',
      image: query.imageDlaMediow,
    },
    {
      slug: 'prezentacja-omida-vls',
      name: lang === 'en' ? 'Company Presentation' : 'Prezentacja firmy',
      image: query.imagePrezentacja,
    },
  ]

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/firma.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={query.image.childImageSharp} h={2} />
      <Main data={main} h={1} />
      <Tiles data={links} />
    </Layout>
  )
}

export default Firma
